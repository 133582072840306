
<template>
<div>
    <div v-if="layout.loader" class="busy-container position-relative">
      <div class="app-busy-mood text-center d-flex justify-content-center align-items-center">
          <img src="/images/loading.gif" width="60">
      </div>
    </div>
    <div>
        <app-header />
        <left-sidebar />
        <div class="content-page">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
        <app-footer />
    </div>
</div>
</template>

<script>

import AppHeader from '@/layouts/components/AppHeader.vue'
import LeftSidebar from '@/layouts/components/LeftSidebar.vue'
import AppFooter from '@/layouts/components/AppFooter.vue'
export default {
  components: {
    AppHeader,
    LeftSidebar,
    AppFooter
  },
  methods:{
    checkSetNotification(){
      if(this.$route.query.notify){
        let notify = this.$route.query.notify
        if(notify == 'email-verified'){
          this.$store.dispatch("fetchActiveUser")
          this.alertSuccess("Your email address has been verified")
        }
      }
    }
  },
  computed: {
    layout()  { return this.$store.state.layout },
  },
  created() {
    this.$store.dispatch("orderList/fetchOrders")
    this.$store.dispatch("notification/fetchNotifications")
    this.checkSetNotification()
  },
}
</script>

<style type="text/css">
    .content-page{
      min-height: 80vh;
      padding: 58px 0px 100px 0px;
      overflow: hidden;
    }
  .app-busy-mood{
    background: rgb(237 229 231);
    position: fixed;
    top: 0;
    z-index: 99999;
    opacity:0.4;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .font-sm{
    font-size:13px;
  }
  .cursor-pointer{
    cursor: pointer !important;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #f2f3f5;
  }

</style>
