<template>

<div class="header-static app-header navbar-sticky">
    <nav id="firstNavHeader" class="navbar top-header-bar navbar-expand-lg text-white navbar-dark bg-dark">
        <div class="container">
            <button v-b-toggle.sidebar-backdrop class="navbar-toggler px-0" style="margin-left:0px; outline: none;" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Brand -->
            <a class="navbar-brand ml-5 ml-lg-0 d-flex align-items-center" :href="`${$store.state.domain}`">
                <img :src="`${$store.state.domain}/images/logos/logo-light.svg`" class="navbar-brand-img" alt="...">
            </a>
            <ul class="navbar-nav w-lg-100 justify-content-end align-items-lg-center">
                <li>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item">
                                <router-link class="nav-link" to="/">
                                    Dashboard
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/orders">
                                    Orders
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/notifications">
                                    Notification
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item log-sign">
                    <span class="nav-link py-0">
                        <b-dropdown id="dropdown-right" right variant="primary" class="user-dropdown-auth">
                            <template #button-content>
                                <i class="fa fa-user "></i> <i class="mdi mdi-chevron-down"></i>
                            </template>
                            <b-dropdown-item href="#" @click.prevent="$router.push('/account').catch(()=>{})"><i class="fa fa-address-book mr-1"></i> Profile</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="$router.push('/account?tab=settings').catch(()=>{})"><i class="fa fa-cog mr-1"></i>  Settings</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="$router.push('/account?tab=password').catch(()=>{})"><i class="fa fa-lock mr-1"></i>  Password</b-dropdown-item>
                            <b-dropdown-item href="#" @click.prevent="logout()"><i class="fa fa-sign-out-alt mr-1"></i> Logout</b-dropdown-item>
                        </b-dropdown>
                    </span>
                </li>
            </ul>
        </div>
  </nav>
</div>

</template>


<script>

export default {
  data() {
    return {

    }
  },
  computed: {
    activeUser() {
      return this.$store.state.activeUser
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("changeLoaderValue",true)
      this.$store.dispatch("logoutUser")
      this.$http.defaults.headers.Authorization = "";
    },
  }
}
</script>

<style>
.app-header{
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    z-index: 1001;
}
.user-dropdown-auth .dropdown-menu .dropdown-item{
    color:rgb(34, 33, 33) !important;
}
.user-dropdown-auth .dropdown-menu .dropdown-item:hover{
    color:#000 !important;
}
</style>
